import { createSlice } from '@reduxjs/toolkit';
import {
  PIECE_COMBINATIONS,
  GameStateEnum,
  UserMoveStateEnum as ServerUserMoveStateEnum
} from '../utils/Const';

const AVAILABLE_PIECES_BOARD_DIM = { height: 2, width: 8 };
const PLACED_PIECES_BOARD_DIM = { height: 4, width: 4 };

export const UserMoveStateEnum = {
  PENDING: 0,
  SELF_SELECT_PIECE: 1,
  OPPONENT_PLACE_PIECE: 2,
  OPPONENT_SELECT_PIECE: 3,
  SELF_PLACE_PIECE: 4,
  COMPLETE: 5
};

const UserMoveStateMachine = {
  [UserMoveStateEnum.SELF_PLACE_PIECE]: UserMoveStateEnum.SELF_SELECT_PIECE,
  [UserMoveStateEnum.SELF_SELECT_PIECE]: UserMoveStateEnum.OPPONENT_PLACE_PIECE,
  [UserMoveStateEnum.OPPONENT_PLACE_PIECE]:
    UserMoveStateEnum.OPPONENT_SELECT_PIECE,
  [UserMoveStateEnum.OPPONENT_SELECT_PIECE]: UserMoveStateEnum.SELF_PLACE_PIECE
};

const getBoardPieces = () => {
  return [...PIECE_COMBINATIONS].sort(() => 0.5 - Math.random());
};

const generateBoard = ({ height, width }, pieces = []) => {
  const board = [];
  const reverseMap = {};
  for (let x = 0; x < width; x++) {
    board[x] = [];
    for (let y = 0; y < height; y++) {
      const piece = pieces.pop() || null;
      if (piece) reverseMap[piece.key] = { x, y };
      board[x][y] = piece;
    }
  }
  return { board, reverseMap };
};

const generateBoardWithPieces = dim => generateBoard(dim, getBoardPieces());

const availablePieces = generateBoardWithPieces(AVAILABLE_PIECES_BOARD_DIM);

const initialState = {
  playerOneUserId: undefined,
  playerTwoUserId: undefined,
  gameState: GameStateEnum.PENDING,
  userMoveState: UserMoveStateEnum.PENDING,
  selectedPiece: null,
  availablePiecesBoard: availablePieces.board,
  placedPiecesBoard: generateBoard(PLACED_PIECES_BOARD_DIM).board,
  availablePiecesReverseMap: availablePieces.reverseMap,
  winnerUserId: null,
  winningPaths: [],
  playersRequestingRematch: {},
  runningWinCount: {},
  moves: []
};

// Index 0 of value is if P1, else Index 1 of value
const MappedServerUserMoveState = {
  [ServerUserMoveStateEnum.PENDING]: [
    UserMoveStateEnum.PENDING,
    UserMoveStateEnum.PENDING
  ],
  [ServerUserMoveStateEnum.COMPLETE]: [
    UserMoveStateEnum.COMPLETE,
    UserMoveStateEnum.COMPLETE
  ],
  [ServerUserMoveStateEnum.P1_SELECT_PIECE]: [
    UserMoveStateEnum.SELF_SELECT_PIECE,
    UserMoveStateEnum.OPPONENT_SELECT_PIECE
  ],
  [ServerUserMoveStateEnum.P1_PLACE_PIECE]: [
    UserMoveStateEnum.SELF_PLACE_PIECE,
    UserMoveStateEnum.OPPONENT_PLACE_PIECE
  ],
  [ServerUserMoveStateEnum.P2_SELECT_PIECE]: [
    UserMoveStateEnum.OPPONENT_SELECT_PIECE,
    UserMoveStateEnum.SELF_SELECT_PIECE
  ],
  [ServerUserMoveStateEnum.P2_PLACE_PIECE]: [
    UserMoveStateEnum.OPPONENT_PLACE_PIECE,
    UserMoveStateEnum.SELF_PLACE_PIECE
  ]
};

const getUserMoveState = (gameState, myUserID) => {
  if (gameState.playerOneUserId === myUserID) {
    return MappedServerUserMoveState[gameState.userMoveState][0];
  } else if (gameState.playerTwoUserId === myUserID) {
    return MappedServerUserMoveState[gameState.userMoveState][1];
  }
  console.log('Did not implement logic for observers');
  throw Error('Did not implement logic for observers');
};

const readBoardState = (localState, serverGameData, myUserID) => {
  localState.gameState = serverGameData.gameState;
  localState.userMoveState = getUserMoveState(serverGameData, myUserID);
  localState.selectedPiece =
    PIECE_COMBINATIONS[serverGameData.selectedPieceKey] || null;
  const availablePieces = generateBoard(
    AVAILABLE_PIECES_BOARD_DIM,
    serverGameData.availablePieces.map(key => PIECE_COMBINATIONS[key])
  );
  localState.availablePiecesBoard = availablePieces.board;
  localState.placedPiecesBoard = serverGameData.board;
  localState.availablePiecesReverseMap = availablePieces.reverseMap;
  localState.winnerUserId = serverGameData.winnerUserId;
  localState.winningPaths = serverGameData.winningPaths;
  localState.moves = serverGameData.moves;
  localState.playerOneUserId = serverGameData.playerOneUserId;
  localState.playerTwoUserId = serverGameData.playerTwoUserId;
};

const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    // TODO make work for "visitor" of room by disabling/etc
    joinRoom: (state, { payload: { gameData, userID } }) => {
      readBoardState(state, gameData, userID);
    },
    rematch: (state, { payload: { gameData, userID } }) => {
      readBoardState(state, gameData, userID);
    },
    startGame: (state, { payload: { gameData, userID } }) => {
      // Always needed
      state.gameState = GameStateEnum.IN_PROGRESS;
      state.userMoveState = getUserMoveState(gameData, userID);

      state.playerOneUserId = gameData.playerOneUserId;
      state.playerTwoUserId = gameData.playerTwoUserId;

      state.selectedPiece = null;
      state.availablePiecesBoard = initialState.availablePiecesBoard;
      state.availablePiecesReverseMap = initialState.availablePiecesReverseMap;
      state.placedPiecesBoard = initialState.placedPiecesBoard;
      state.winnerUserId = initialState.winnerUserId;
      state.winningPaths = initialState.winningPaths;
    },
    endGame: (state, { payload: { gameData } }) => {
      state.gameState = GameStateEnum.COMPLETE;
      state.userMoveState = UserMoveStateEnum.COMPLETE;
      state.selectedPiece = null;
      state.winnerUserId = gameData.winnerUserId;
      state.winningPaths = gameData.winningPaths;
    },
    selectPiece: (state, { payload: { gameData } }) => {
      const { x, y } = state.availablePiecesReverseMap[
        gameData.selectedPieceKey
      ];
      const piece = state.availablePiecesBoard[x][y];

      state.moves.push(gameData.selectedPieceKey);

      state.availablePiecesBoard[x][y] = null;
      state.selectedPiece = piece;
      state.userMoveState = UserMoveStateMachine[state.userMoveState];
    },
    placePiece: (state, { payload: { gameData, x, y } }) => {
      // TODO fix this temp workaround for different logic from server/client
      if (gameData) {
        state.placedPiecesBoard = gameData.board;
        state.moves = gameData.moves;
      } else {
        state.placedPiecesBoard[x][y] = state.selectedPiece;
        state.moves.push({ x, y });
      }
      state.selectedPiece = null;
      state.userMoveState = UserMoveStateMachine[state.userMoveState];
    }
  },
  extraReducers: {
    '@@router/LOCATION_CHANGE': (state, { payload }) => {
      if (payload.location.pathname === '/')
        state.userMoveState = UserMoveStateEnum.PENDING;
    }
  }
});

// TODO Add a "state machine" which handles switching between "can move" of select / place piece / wait better

export const {
  joinRoom,
  startGame,
  endGame,
  restartGame,
  selectPiece,
  placePiece,
  rematch
} = boardSlice.actions;

// Thunk Action Creators
// Allow you to perform async work and then dispatch an action

// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// export const fetchPosts = createAsyncThunk('posts/fetchPosts', async () => {
//   const response = await client.get('/fakeApi/posts')
//   return response.posts
// })

// extraReducers: {
//   [fetchPosts.pending]: (state, action) => {
//     state.status = 'loading'
//   },
//   [fetchPosts.fulfilled]: (state, action) => {
//     state.status = 'succeeded'
//     // Add any fetched posts to the array
//     state.posts = state.posts.concat(action.payload)
//   },
//   [fetchPosts.rejected]: (state, action) => {
//     state.status = 'failed'
//     state.error = action.error.message
//   }
// }

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

const selectBoard = state => state.board;
export const selectGameState = state => selectBoard(state).gameState;
export const selectUserMoveState = state => selectBoard(state).userMoveState;
export const selectSelectedPiece = state => selectBoard(state).selectedPiece;
export const selectAvailablePiecesBoard = state =>
  selectBoard(state).availablePiecesBoard;
export const selectPlacedPiecesBoard = state =>
  selectBoard(state).placedPiecesBoard;
export const selectMoves = state => selectBoard(state).moves;
export const selectRunningWinCount = state =>
  selectBoard(state).runningWinCount;
export const selectPlayersRequestingRematch = state =>
  selectBoard(state).playersRequestingRematch;
export const selectWinningPaths = state => selectBoard(state).winningPaths;
export const selectWinnnerUserId = state => selectBoard(state).winnerUserId;
export const selectPlayerOneUserId = state =>
  selectBoard(state).playerOneUserId;
export const selectPlayerTwoUserId = state =>
  selectBoard(state).playerTwoUserId;

export default boardSlice.reducer;
