import React, { useState, useEffect } from 'react';
import { Stage } from 'react-konva';
import { ReactReduxContext, Provider } from 'react-redux';

const CANVAS_VIRTUAL_WIDTH = 900;
const CANVAS_VIRTUAL_HEIGHT = 900;

const getDividerForScreenWidth = screenWidth => {
  if (screenWidth < 1200) {
    return 1.15;
  } else if (screenWidth < 1460) {
    return 2;
  }
  return 1.6;
};

const getResizeScale = () =>
  Math.min(
    window.innerWidth /
      CANVAS_VIRTUAL_WIDTH /
      getDividerForScreenWidth(window.innerWidth),
    window.innerHeight /
      CANVAS_VIRTUAL_HEIGHT /
      getDividerForScreenWidth(window.innerWidth)
  );

const ResponsiveKonvaStage = props => {
  const [scale, setScale] = useState(getResizeScale());

  const handleWindowResize = () => setScale(getResizeScale());

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  });

  return (
    <ReactReduxContext.Consumer>
      {({ store }) => (
        <Stage
          width={CANVAS_VIRTUAL_WIDTH * scale}
          height={CANVAS_VIRTUAL_HEIGHT * scale}
          scaleX={scale}
          scaleY={scale}
          className="d-flex justify-content-center"
        >
          <Provider store={store}>{props.children}</Provider>
        </Stage>
      )}
    </ReactReduxContext.Consumer>
  );
};

export default ResponsiveKonvaStage;
