import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectUserMoveState,
  selectPiece,
  placePiece,
  endGame,
  selectAvailablePiecesBoard,
  selectPlacedPiecesBoard
} from '../../features/boardSlice';
import { UserMoveStateEnum } from '../../features/boardSlice';
import socket from '../../utils/socket';
import { Row, Col } from 'react-bootstrap';
import Board from './Board';
import SelectedPiece from './SelectedPiece';
import ResponsiveKonvaStage from '../konva/ResponsiveKonvaStage';
import Panel from '../panel/Panel';

const BoardManager = ({ shortUrl }) => {
  const userMoveState = useSelector(selectUserMoveState);
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on('pieceSelected', gameData => dispatch(selectPiece({ gameData })));
    socket.on('piecePlaced', gameData => dispatch(placePiece({ gameData })));

    socket.on('endGame', gameData => dispatch(endGame({ gameData })));

    return () => {
      socket.off('pieceSelected');
      socket.off('piecePlaced');
      socket.off('endGame');
    };
  });

  const selfSelectPiece = ({ piece }) => {
    if (piece && userMoveState === UserMoveStateEnum.SELF_SELECT_PIECE) {
      socket.emit(
        'selectPiece',
        { shortUrl, pieceKey: piece.key },
        ({ error }) => error && console.log(error)
      );
      dispatch(selectPiece({ gameData: { selectedPieceKey: piece.key } }));
    }
  };

  const selfPlacePiece = ({ x, y, piece }) => {
    if (!piece && userMoveState === UserMoveStateEnum.SELF_PLACE_PIECE) {
      socket.emit(
        'placePiece',
        { shortUrl, x, y },
        ({ error }) => error && console.log(error)
      );
      dispatch(placePiece({ x, y }));
    }
  };

  return (
    <Row className="vw-100">
      <Col xl={{ offset: 4, span: 4 }}>
        <ResponsiveKonvaStage>
          <SelectedPiece offset={{ x: 400, y: 5 }} />
          <Board
            onClickSquare={selfPlacePiece}
            boardSelector={selectPlacedPiecesBoard}
            offset={{ x: 250, y: 150 }}
            renderWinningPaths={true}
            canClick={piece =>
              !piece && userMoveState === UserMoveStateEnum.SELF_PLACE_PIECE
            }
          />
          <Board
            onClickSquare={selfSelectPiece}
            boardSelector={selectAvailablePiecesBoard}
            offset={{ x: 50, y: 600 }}
            canClick={piece =>
              piece && userMoveState === UserMoveStateEnum.SELF_SELECT_PIECE
            }
          />
        </ResponsiveKonvaStage>
      </Col>
      <Col xs={12} md={12} xl={4}>
        <Panel userID={socket.userID} />
      </Col>
    </Row>
  );
};

export default BoardManager;
