import { configureStore } from '@reduxjs/toolkit';
import boardReducer from './features/boardSlice';
import profileReducer from './features/profileSlice';
import chatReducer from './features/chatSlice';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export default configureStore({
  reducer: {
    router: connectRouter(history),
    board: boardReducer,
    profile: profileReducer,
    chat: chatReducer
  }
});
