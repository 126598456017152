import React, { useState } from 'react';
import { Button, Card, Col, Alert } from 'react-bootstrap';
import { createRoom } from '../../utils/api';
import { useHistory } from 'react-router-dom';
import socket from '../../utils/socket';

const Lobby = props => {
  const [state, setState] = useState({ errorMsg: null });

  const history = useHistory();

  const createRoomAndRedirect = async () => {
    try {
      const roomName = await createRoom(socket.auth.sessionID);
      history.push(`${roomName}`);
    } catch (error) {
      setState({ errorMsg: error.message });
    }
  };

  return (
    <Col sm="5" className="Lobby">
      {state.errorMsg && <Alert color="danger">{state.errorMsg}</Alert>}
      <Card body>
        <Card.Title tag="h5">Play</Card.Title>
        <Card.Text>Challenge a friend to a game of Quarto!</Card.Text>
        <Button variant="outline-secondary" onClick={createRoomAndRedirect}>
          Create Game Room
        </Button>
      </Card>
    </Col>
  );
};

export default Lobby;
