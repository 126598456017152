const axios = require('axios');

const authAxios = axios.create();

authAxios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => Promise.reject(error)
);

export const createRoom = async sessionID => {
  try {
    const { data } = await authAxios.post('api/games', { sessionID });
    return data.shortUrl;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createAuthToken = async username => {
  try {
    const { data } = await axios.post(`${window.location.origin}/api/session`, {
      username
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getUserDetailsWithAuthToken = async () => {
  try {
    const { data } = await authAxios.get(
      `${window.location.origin}/api/session`
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
