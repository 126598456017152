import { Badge, Row, Col, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MatchStatusText from './MatchStatusText';

const Header = () => {
  return (
    <Col sm="12">
      <Row className="justify-content-center">
        <Navbar>
          <Navbar.Brand as={Link} to="/">
            <h1>
              Quarto <Badge variant="secondary">Live</Badge>
            </h1>
          </Navbar.Brand>
        </Navbar>
      </Row>
      <Row className="justify-content-center">
        <MatchStatusText />
      </Row>
    </Col>
  );
};

export default Header;
