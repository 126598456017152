import { Rect } from 'react-konva';
import Piece from './Piece';
import { Group } from 'react-konva';
import { selectWinningPaths } from '../../features/boardSlice';
import { useSelector } from 'react-redux';

const SQUARE_SIZE = 100;
const SQUARE_COLOR = '#F8F9Fa'; // Light grey
const HIGHLIGHTED_SQUARE_COLOR = '#C9FFC1'; // Yellow

const BoardSquare = ({
  x,
  y,
  offset,
  piece,
  onClick,
  canClick,
  renderWinningPaths
}) => {
  // const [cursorText, setCursorText] =
  const winningPaths = useSelector(selectWinningPaths);

  const squareColor = () => {
    if (renderWinningPaths) {
      for (const path of winningPaths) {
        for (const point of path) {
          if (x === point.x && y === point.y) {
            return HIGHLIGHTED_SQUARE_COLOR;
          }
        }
      }
    }
    return SQUARE_COLOR;
  };

  const setCursor = style => (document.body.style.cursor = style);

  return (
    <Group
      onTouchStart={e => {
        onClick({ x, y, piece });
      }}
      onClick={() => {
        setCursor('default');
        onClick({ x, y, piece });
      }}
      onMouseEnter={() => canClick(piece) && setCursor('pointer')}
      onMouseLeave={() => setCursor('default')}
      on
    >
      <Rect
        role="button"
        x={x * SQUARE_SIZE + offset.x}
        y={y * SQUARE_SIZE + offset.y}
        width={SQUARE_SIZE}
        height={SQUARE_SIZE}
        fill={squareColor()}
        shadowBlur={2}
      />
      {piece ? (
        <Piece
          piece={piece}
          x={x}
          y={y}
          squareSize={SQUARE_SIZE}
          holeColor={squareColor()}
          offset={offset}
        />
      ) : null}
    </Group>
  );
};

export default BoardSquare;
