import { Card, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  rematch,
  selectMoves,
  selectPlayerOneUserId,
  selectPlayerTwoUserId
} from '../../features/boardSlice';
import { PIECE_COMBINATIONS } from '../../utils/Const';

const Panel = ({ userID }) => {
  const moves = useSelector(selectMoves);
  const playerOneUserId = useSelector(selectPlayerOneUserId);
  const playerTwoUserId = useSelector(selectPlayerTwoUserId);

  const describeSelection = pieceKey => {
    if (pieceKey === undefined) return '';
    console.log(pieceKey);
    const piece = PIECE_COMBINATIONS[pieceKey];
    return `${piece.color ? 'purple' : 'blue'} ${
      piece.hole ? 'solid' : 'hollow'
    } ${piece.size ? 'small' : 'big'} ${piece.shape ? 'square' : 'circle'}`;
  };

  const mapMovesToTableRows = () => {
    const mappedMoves = [];

    let lastPlace;
    let plr = playerOneUserId;
    for (let i = 0; i < moves.length; i++) {
      if (moves[i] instanceof Object) {
        plr = plr === playerOneUserId ? playerTwoUserId : playerOneUserId;
        lastPlace = moves[i];
        if (i === moves.length - 1)
          mappedMoves.push({ plr, lastPlace, selectedPieceKey: undefined });
      } else {
        mappedMoves.push({ plr, lastPlace, selectedPieceKey: moves[i] });
      }
    }

    return mappedMoves;
  };

  return (
    <Card>
      <Card.Header>
        {[playerOneUserId, playerTwoUserId]
          .filter(id => id !== userID)[0]
          .substring(0, 10)}
      </Card.Header>
      <Card.Body>
        <Table striped bordered size="sm" responsive>
          <thead>
            <tr>
              <th></th>
              <th>Placed</th>
              <th>Selected</th>
            </tr>
          </thead>
          <tbody>
            {mapMovesToTableRows().map(
              ({ plr, lastPlace, selectedPieceKey }, i) => (
                <tr key={i}>
                  <td>{plr.substring(0, 10)}</td>
                  <td>{lastPlace ? `[${lastPlace.x}, ${lastPlace.y}]` : ''}</td>
                  <td>{describeSelection(selectedPieceKey)}</td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>{userID.substring(0, 10)}</Card.Footer>
    </Card>
  );
};

export default Panel;
