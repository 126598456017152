import React, { useState } from 'react';
import { Form, Col, Button, Card, InputGroup, Alert } from 'react-bootstrap';

const COPY_TEXT_ERROR_MESSAGE = 'Exception thrown when copying text!';

const WaitingCard = ({ shortUrl, roomExists }) => {
  const fullUrl = `${window.location.origin}/${shortUrl}`;

  const [copyButtonProps, updateCopyButtonProps] = useState({
    variant: 'secondary',
    text: 'Copy'
  });

  const textInput = React.createRef();

  const clipBoardToast = success => {
    if (success) {
      updateCopyButtonProps({ variant: 'success', text: 'Copied!' });
    } else {
      updateCopyButtonProps({ variant: 'danger', text: 'Error!' });
    }
    setTimeout(() => {
      updateCopyButtonProps({ variant: 'secondary', text: 'Copy' });
    }, 1000);
  };

  const onClickCopyButton = async () => {
    let success = false;

    try {
      if (navigator.clipboard !== undefined) {
        await navigator.clipboard.writeText(fullUrl);
        success = true;
      } else {
        if (textInput) {
          textInput.current.focus();
          textInput.current.select();
          success = document.execCommand('copy');
        }
      }
    } catch (e) {
      console.error(COPY_TEXT_ERROR_MESSAGE);
    }
    clipBoardToast(success);
  };

  return (
    <Col sm="4">
      {!roomExists ? (
        <Alert variant="danger">{`No room was created with the url ${fullUrl}`}</Alert>
      ) : (
        <Card>
          <Card.Body>
            <Card.Title tag="h5">Challenge a friend to Quarto</Card.Title>
            <Form>
              <InputGroup>
                <Form.Control ref={textInput} placeholder={fullUrl} disabled />
                <InputGroup.Append>
                  <Button
                    variant={copyButtonProps.variant}
                    onClick={onClickCopyButton}
                  >
                    {copyButtonProps.text}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Col>
  );
};
export default WaitingCard;
