import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
  name: 'chat',
  initialState: {},
  reducers: {}
});

// Actions

// Thunk Action Creators

// Selectors
// const selectChat = state => state.chat;

export default chatSlice.reducer;
