import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  joinRoom,
  startGame,
  selectGameState
} from '../../features/boardSlice';
import { GameStateEnum } from '../../utils/Const';
import BoardManager from '../board/BoardManager';
import WaitingCard from './WaitingCard';
import { Row, Col, Spinner } from 'react-bootstrap';
import socket from '../../utils/socket';

const Room = props => {
  const dispatch = useDispatch();
  const shortUrl = props.match.params.shortUrl;
  const [roomExists, updateRoomExists] = useState(true);
  const [joiningRoom, updateJoiningRoom] = useState(true);
  const gameState = useSelector(selectGameState);

  useEffect(() => {
    socket.on('playerJoinedRoom', () =>
      console.log('Another player joined the room!')
    );

    socket.on('startGame', gameData => {
      updateJoiningRoom(false);
      dispatch(startGame({ gameData, userID: socket.userID }));
    });

    socket.emit('joinRoom', { shortUrl }, gameData => {
      updateJoiningRoom(false);
      if (gameData && !gameData.error) {
        dispatch(joinRoom({ gameData, userID: socket.userID }));
        updateRoomExists(true);
      } else {
        updateRoomExists(false);
      }
    });

    return () => {
      socket.off('startGame');
      socket.off('playerJoinedRoom');
    };
  }, [shortUrl, dispatch]);

  return (
    <Fragment>
      <Col sm="12" className="Room bg-light bg-gradient">
        <Row className="justify-content-center">
          {joiningRoom ? (
            <Spinner animation="border" variant="secondary" />
          ) : gameState === GameStateEnum.PENDING ? (
            <WaitingCard shortUrl={shortUrl} roomExists={roomExists} />
          ) : (
            <BoardManager shortUrl={shortUrl} />
          )}
        </Row>
      </Col>
    </Fragment>
  );
};

export default Room;
