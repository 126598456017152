import { createSlice } from '@reduxjs/toolkit';

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    username: '',
    userID: ''
  },
  reducers: {}
});

// Actions

// Thunk Action Creators

// Selectors
// const selectProfile = state => state.profile;

export default profileSlice.reducer;
