const BINARY_ATTRIBUTE = [0, 1];

export const PIECE_COMBINATIONS = [];

let i = 0;

BINARY_ATTRIBUTE.forEach(color => {
  BINARY_ATTRIBUTE.forEach(size => {
    BINARY_ATTRIBUTE.forEach(shape => {
      BINARY_ATTRIBUTE.forEach(hole =>
        PIECE_COMBINATIONS.push({ color, size, shape, hole, key: i++ })
      );
    });
  });
});

export const GameStateEnum = {
  PENDING: 0,
  IN_PROGRESS: 1,
  COMPLETE: 2
};

export const UserMoveStateEnum = {
  PENDING: 0,
  P1_SELECT_PIECE: 1,
  P2_PLACE_PIECE: 2,
  P2_SELECT_PIECE: 3,
  P1_PLACE_PIECE: 4,
  COMPLETE: 5
};

export const UserMoveStateMachine = {
  [UserMoveStateEnum.P1_PLACE_PIECE]: UserMoveStateEnum.P1_SELECT_PIECE,
  [UserMoveStateEnum.P1_SELECT_PIECE]: UserMoveStateEnum.P2_PLACE_PIECE,
  [UserMoveStateEnum.P2_PLACE_PIECE]: UserMoveStateEnum.P2_SELECT_PIECE,
  [UserMoveStateEnum.P2_SELECT_PIECE]: UserMoveStateEnum.P1_PLACE_PIECE
};
