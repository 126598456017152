import { Rect, Circle, Group } from 'react-konva';

const SIZES = [75, 45];
const PIECE_COLORS = ['#A1CCEB', '#CBB1EF'];
// const PIECE_COLORS = ['#F1948A', '#C39BD3'];
const SHAPES = ['CIRCLE', 'SQUARE'];
const HOLES = [true, false];

const HOLE_SIZE = 12;

// TODO should make piece expand in size slightly on hover when it is able to be chosen.
// Use pulse: https://konvajs.org/docs/react/Access_Konva_Nodes.html

const Piece = ({
  piece: { color, hole, size, shape },
  x,
  y,
  squareSize,
  holeColor,
  offset
}) => {
  const absoluteX = ((2 * x + 1) * squareSize) / 2 + offset.x;
  const absoluteY = ((2 * y + 1) * squareSize) / 2 + offset.y;

  const getCircle = () => (
    <Circle
      radius={SIZES[size] / 2}
      x={absoluteX}
      y={absoluteY}
      fill={PIECE_COLORS[color]}
    />
  );

  const getRect = () => (
    <Rect
      width={SIZES[size]}
      height={SIZES[size]}
      x={absoluteX - SIZES[size] / 2}
      y={absoluteY - SIZES[size] / 2}
      fill={PIECE_COLORS[color]}
    />
  );

  const getHole = () =>
    HOLES[hole] ? (
      <Circle radius={HOLE_SIZE} x={absoluteX} y={absoluteY} fill={holeColor} />
    ) : null;

  return (
    <Group>
      {SHAPES[shape] === 'CIRCLE' ? getCircle() : getRect()}
      {getHole()}
    </Group>
  );
};

export default Piece;
