import { useSelector } from 'react-redux';
import { selectSelectedPiece } from '../../features/boardSlice';
import BoardSquare from './BoardSquare';
import { Layer } from 'react-konva';

const SelectedPiece = ({ offset, squareSize }) => {
  const selectedPiece = useSelector(selectSelectedPiece);

  return (
    <Layer>
      <BoardSquare
        x={0}
        y={0}
        offset={offset}
        piece={selectedPiece}
        onClick={() => {}}
        canClick={() => false}
      />
    </Layer>
  );
};

export default SelectedPiece;
