import { Layer, Group } from 'react-konva';
import BoardSquare from './BoardSquare';
import { useSelector } from 'react-redux';

const Board = ({
  boardSelector,
  offset,
  onClickSquare,
  canClick,
  renderWinningPaths
}) => {
  const board = useSelector(boardSelector);
  return (
    <Layer>
      {board.map((row, x) => (
        <Group key={x}>
          {row.map((piece, y) => (
            <BoardSquare
              key={y}
              x={x}
              y={y}
              offset={offset}
              piece={piece}
              onClick={onClickSquare}
              canClick={canClick}
              renderWinningPaths={renderWinningPaths}
            />
          ))}
        </Group>
      ))}
    </Layer>
  );
};

export default Board;
