import { useSelector } from 'react-redux';
import {
  selectWinnnerUserId,
  selectUserMoveState
} from '../../features/boardSlice';
import { UserMoveStateEnum } from '../../features/boardSlice';
import socket from '../../utils/socket';

const MatchStatusText = () => {
  const userMoveState = useSelector(selectUserMoveState);
  const winnerUserId = useSelector(selectWinnnerUserId);

  const body = () => {
    switch (userMoveState) {
      case UserMoveStateEnum.SELF_SELECT_PIECE:
        return 'You must select a piece for your partner to place on the board.';
      case UserMoveStateEnum.OPPONENT_SELECT_PIECE:
        return 'Waiting for opponent to select a piece for you to place...';
      case UserMoveStateEnum.SELF_PLACE_PIECE:
        return 'You must place the selected piece on the board.';
      case UserMoveStateEnum.OPPONENT_PLACE_PIECE:
        return 'Waiting for opponent to place the selected piece...';
      case UserMoveStateEnum.COMPLETE:
        if (winnerUserId === socket.userID) {
          return 'You are victorious!!!!!';
        } else if (winnerUserId === -1) {
          return 'The game is a draw';
        }
        return 'You have been defeated :(';
      default:
        return '';
    }
  };

  return <p className="text-muted">{body()}</p>;
};

export default MatchStatusText;
