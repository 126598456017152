import React, { useEffect } from 'react';
import Lobby from './lobby/Lobby';
import Room from './room/Room';
import Header from './header/Header';

import { Container, Row } from 'react-bootstrap';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import socket from '../utils/socket';
import { getUserDetailsWithAuthToken, createAuthToken } from '../utils/api';
import { history } from '../store';

const App = () => {
  useEffect(() => {
    const connectAuthToken = async () => {
      let token = localStorage.getItem('token');
      let username, userID;
      if (token) {
        ({ username, userID } = await getUserDetailsWithAuthToken(token));
      } else {
        const res = await createAuthToken();
        ({ username, userID, token } = res);
        localStorage.setItem('token', token);
      }
      socket.auth = { token };
      socket.userID = userID;
      socket.username = username;
      socket.connect();
    };

    connectAuthToken();

    return () => socket.disconnect();
  }, []);

  return (
    <ConnectedRouter history={history}>
      <Container className="App vh-100 bg-light bg-gradient" fluid={true}>
        <Row className="vh-100 justify-content-center">
          <Header />
          <Switch>
            <Route exact path="/:shortUrl" component={Room} />
            <Route exact path="/">
              <Lobby />
            </Route>
            <Route path="/">
              {
                // TODO make all routes exact and have a 404 catch all after
              }
              <Lobby />
            </Route>
          </Switch>
        </Row>
      </Container>
    </ConnectedRouter>
  );
};

export default App;
